import React from "react";
import useLockBodyScroll from "../hooks/layout/useLockBodyScroll";

const ModalOverlay = ({ children, className, style = {} }) => {
  useLockBodyScroll(true);
  return (
    <div className={className} style={style} role="dialog">
      {children}
    </div>
  );
};

export default ModalOverlay;
